import {
  UPDATE_LANGUAGE,
  LocalizationAction,
  LocalizationState,
} from './Localization.types'

export default (
  state: LocalizationState,
  action: LocalizationAction
): LocalizationState => {
  switch (action.type) {
    case UPDATE_LANGUAGE:
      return {
        ...state,
        language: action.payload,
      }
    default:
      return state
  }
}
