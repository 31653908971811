import React from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

// Components
import UserProvider from './src/context/User/User.state'
import LocalizationProvider from './src/context/Localization/Localization.state'

// Style
import './src/styles/index.scss'

export const wrapRootElement = ({ element }) => (
  <GoogleReCaptchaProvider reCaptchaKey={process.env.RECAPTCHA_KEY}>
    <UserProvider>
      <LocalizationProvider>{element}</LocalizationProvider>
    </UserProvider>
  </GoogleReCaptchaProvider>
)
