export const UPDATE_USER = 'UPDATE_USER'
import { UserModel } from '../../models'

export type UserState = {
  user: null | Partial<UserModel>
  updateUser: (user: null | Partial<UserModel>) => void
}

export type UserAction = {
  type: 'UPDATE_USER'
  payload: null | Partial<UserModel>
}
