export const UPDATE_LANGUAGE = 'UPDATE_LANGUAGE'
import { ValidLanguage } from '../../types'

export type LocalizationState = {
  language: ValidLanguage
  updateLanguage: (language:ValidLanguage) => void
}

export type LocalizationAction = {
  type: 'UPDATE_LANGUAGE'
  payload: ValidLanguage
}
