// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-templates-chapter-index-tsx": () => import("./../../../src/components/templates/Chapter/index.tsx" /* webpackChunkName: "component---src-components-templates-chapter-index-tsx" */),
  "component---src-components-templates-character-index-tsx": () => import("./../../../src/components/templates/Character/index.tsx" /* webpackChunkName: "component---src-components-templates-character-index-tsx" */),
  "component---src-components-templates-end-of-series-index-tsx": () => import("./../../../src/components/templates/EndOfSeries/index.tsx" /* webpackChunkName: "component---src-components-templates-end-of-series-index-tsx" */),
  "component---src-components-templates-series-index-tsx": () => import("./../../../src/components/templates/Series/index.tsx" /* webpackChunkName: "component---src-components-templates-series-index-tsx" */),
  "component---src-components-templates-town-index-tsx": () => import("./../../../src/components/templates/Town/index.tsx" /* webpackChunkName: "component---src-components-templates-town-index-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-account-tsx": () => import("./../../../src/pages/account.tsx" /* webpackChunkName: "component---src-pages-account-tsx" */),
  "component---src-pages-comic-tsx": () => import("./../../../src/pages/comic.tsx" /* webpackChunkName: "component---src-pages-comic-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-support-tsx": () => import("./../../../src/pages/support.tsx" /* webpackChunkName: "component---src-pages-support-tsx" */),
  "component---src-pages-world-bios-index-tsx": () => import("./../../../src/pages/world/bios/index.tsx" /* webpackChunkName: "component---src-pages-world-bios-index-tsx" */),
  "component---src-pages-world-language-index-tsx": () => import("./../../../src/pages/world/language/index.tsx" /* webpackChunkName: "component---src-pages-world-language-index-tsx" */),
  "component---src-pages-world-map-index-tsx": () => import("./../../../src/pages/world/map/index.tsx" /* webpackChunkName: "component---src-pages-world-map-index-tsx" */),
  "component---src-pages-world-story-index-tsx": () => import("./../../../src/pages/world/story/index.tsx" /* webpackChunkName: "component---src-pages-world-story-index-tsx" */)
}

